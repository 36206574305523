<template>
  <el-card class="page-card">
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      :refactor="tableDataRefactor"
      @backflow="backflow"
      @detail="detail"
      :isSearch="isSearch"
    />
  </el-card>
</template>
<script>
import SuiList from "@/components/s-ui/list";
import { GetPayOrderListByAdmin } from "@/utils/api";
import { formatDataTime, navto } from "@/utils";

export default {
  components: { SuiList },
  data() {
    return {
      isSearch: true,
      status: 0,
      tableColumn: [
        {
          name: "outTradeNo",
          label: "content.order.orderno",
        },
        {
          name: "orderList[0].storeName",
          label: "content.order.storeName",
          width: 120,
        },
        {
          name: "orderList[0].country",
          label: "content.order.country",
            width: 100,
        },
        // {
        //   name: "total",
        //   label: "content.order.total",
        //   formatFn: formatToDecimal,
        //   width: 100,
        // },
        {
          type: "price-fixed",
          name: "total",
          label: "content.order.total",
          width: 200,
        },
        {
          name: "status",
          label: "content.order.status",
          status: this.$t("content.order.statusText"),
        },
        {
          name: "orderList[0].username",
          label: "content.order.username",
        },
        {
          name: "orderList[0].phone",
          label: "content.order.consignee",
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      tableFun: GetPayOrderListByAdmin,
      tableFunReq: {},
      tableDataRefactor: (list) => {
        console.log("list", list);
        return list.map((v) => {
          return {
            ...v,
            currencyTo: v.currency,
            isPaid: v.isPaid,
          };
        });
      },
      count: 0,
    };
  },
  methods: {
    backflow(count) {
      this.count = count;
    },
    detail(item) {
      console.log("navtodetail:", item);
      if (item.orderList[0]) {
        this.$router.push({
          path: "/order/detail",
          query: { id: item.ID, type: "billing" },
        });
      } else {
        navto("/order/detail", { id: item.id });
      }
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>